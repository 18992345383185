<template>
  <div class="youth-welfare" v-if="hasPerm('youth.view_seanceinscription')">
    <div class="youth-welfare-table" :id="'youth-welfare-table-' + id">
      <div class="filter-bar no-print">
        <b-row>
          <b-col cols="8">
            <seances-list-filter
              @changed="onListFilterChanged($event)"
              @loaded="onListFilterLoaded($event)"
              stacked
              multi
              :id="id"
            >
            </seances-list-filter>
          </b-col>
          <b-col>
            <div>
              <date-frame-selector @change="onDateFrameChanged">
              </date-frame-selector>
              <div class="custom-range-holder no-print">
                <div><b>Tranches de QF</b></div>
                <b-row>
                  <b-col>
                    <number-input v-model="familyLevel1" id="familyLevel1" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                  <b-col>
                    <number-input v-model="familyLevel2" id="familyLevel2" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                </b-row>
              </div>
              <div class="custom-range-holder no-print">
                <div><b>Âge</b></div>
                <b-row>
                  <b-col>
                    <number-input v-model="age1" id="age1" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                  <b-col>
                    <number-input v-model="age2" id="age2" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="!isLoading(loadingName)">
        <div v-if="items.length === 0" class="empty-text">
          <div v-if="isValid">Aucune données disponibles pour ce filtre</div>
          <div v-else>Veuillez sélectionner via le filtre les données à afficher</div>
        </div>
        <div v-if="items.length" class="section">
          <b-row><b-col><div class="section-header"><b>{{ subTitle() }}</b></div></b-col></b-row>
          <b-row>
            <b-col>
              <table class="full-table table-bordered table-striped">
                <tr>
                  <td colspan="4"></td>
                  <th class="col total-col" :style="colStyle('total')">Total</th>
                  <td
                    v-for="col of rootColumns"
                    :key="col.id" colspan="2" class="text-center"
                    :style="colStyle('span2')"
                  >
                    <b>{{ col.name }}</b>
                  </td>
                  <th class="welfare-col" :style="colStyle('welfare')">Total</th>
                  <th class="total-col" :style="colStyle('total')">Total</th>
                  <td></td>
                </tr>
                <tr>
                  <th style="max-width: 100px">Nom et Prénom</th>
                  <th>Né(e) le</th>
                  <th>Ville</th>
                  <th>QF</th>
                  <th class="total-col" :style="colStyle('total')">Avant aides</th>
                  <th v-for="col of columns" :key="col.id" :style="colStyle('')">
                    {{ col.name }}
                  </th>
                  <th class="welfare-col" :style="colStyle('welfare')">Aides</th>
                  <th class="total-col" :style="colStyle('total')">Coût familles</th>
                  <th>Analytique</th>
                </tr>
                <tr v-for="item of items" :key="item.key">
                  <td style="max-width: 100px">{{ item.individual.lastAndFirstName() }}</td>
                  <td style="width: 80px" class="date">{{ item.individual.birthDateAsString() }}</td>
                  <td style="width: 120px">{{ item.entity.city.name }}</td>
                  <td class="number">{{ item.familyLevel }}</td>
                  <td class="number total-col" :style="colStyle('total')">
                    {{ sumBefore(item) | currency }}
                  </td>
                  <td v-for="col of columns" :key="col.id" class="number" :style="colStyle('')">
                    {{ getCategoryValue(col, item) }}
                  </td>
                  <td class="number welfare-col" :style="colStyle('welfare')">
                    {{ sumWelfare(item) | currency }}
                  </td>
                  <td class="number total-col" :style="colStyle('total')">
                    {{ sumAmount(item) | currency }}
                  </td>
                  <td class="text-center">{{ getAnalyticCode(item) }}</td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <th class="number total-col" :style="colStyle('total')">
                    {{ totalBefore() | currency }}
                  </th>
                  <th v-for="col of columns" :key="col.id" class="number" :style="colStyle('')">
                    {{ getCategorySum(col) }}
                  </th>
                  <th class="number welfare-col" :style="colStyle('welfare')">
                    {{ totalWelfare() | currency }}
                  </th>
                  <th class="number total-col" :style="colStyle('total')">
                    {{ totalAmount() | currency }}
                  </th>
                  <td></td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col></b-col>
            <b-col cols="3">
              <div style="margin-top: 20px;"><b>Répartition de l'aide</b></div>
              <table class="full-table table-bordered table-striped" title="Répartition de l'aide">
                <tr v-for="analytic of analyticAccounts" :key="analytic.id">
                  <th>{{ analytic.name }}</th>
                  <td class="number" style="width: 30%;">{{ sumAnalytic(analytic) | currency }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import SeancesListFilter from '@/components/Seances/Config/SeancesListFilter'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import NumberInput from '@/components/Controls/NumberInput.vue'
import { BackendMixin } from '@/mixins/backend'
import { currency, dateToString } from '@/filters/texts'
import { BackendApi } from '@/utils/http'
import { sum } from '@/utils/math'
import { makeAnalyticAccount } from '@/types/payments'
import { makeSeanceWelfare } from '@/types/tariffs'
import { makeIndividual, makeEntity } from '@/types/people'

export default {
  name: 'youth-welfare-table',
  mixins: [BackendMixin],
  components: {
    NumberInput,
    LoadingGif,
    SeancesListFilter,
    DateFrameSelector,
  },
  props: {
    id: String,
    printMode: Boolean,
  },
  data() {
    return {
      youthHomes: [],
      seanceTypes: [],
      periods: [],
      familyLevel1: null,
      familyLevel2: null,
      age1: null,
      age2: null,
      startDate: null,
      endDate: null,
      analyticAccounts: [],
      analyticAccountsMap: new Map(),
      welfare: [],
      items: [],
      rootColumns: [
        { id: 1, category: '4', name: 'Journées sans repas', },
        { id: 2, category: '2', name: 'Demies-journées sans repas', },
        { id: 3, category: '5', name: 'Journées avec repas', },
        { id: 4, category: '3', name: 'Demies-journées avec repas', }
      ],
    }
  },
  computed: {
    isValid() {
      return (
        this.youthHomes.length && this.seanceTypes.length && this.periods.length
      )
    },
    columns() {
      let columns = []
      for (const col of this.rootColumns) {
        columns.push(
          {
            id: col.id * 2 - 1,
            field: 'counters',
            category: col.category,
            name: 'Nb.',
          }
        )
        columns.push(
          {
            id: col.id * 2,
            field: 'welfare',
            category: col.category,
            name: 'Aides',
          }
        )
      }
      return columns
    },
    loadingName() {
      return 'youth-welfare-' + this.id
    },
  },
  mounted() {
  },
  watch: {
    printMode: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadStats()
      this.endLoading(this.loadingName)
    },
    colStyle(styleName) {
      const style = {}
      if (this.printMode) {
        if (styleName === 'total') {
          style.background = '#f0f0f0 !important'
        } else if (styleName === 'welfare') {
          style.background = '#c0c0c0 !important'
        }
      }
      if (styleName === 'span2') {
        style.width = '80px'
      } else {
        style.width = '40px'
      }
      return style
    },
    subTitle() {
      let texts = []
      if (this.youthHomes) {
        texts = texts.concat(this.youthHomes.map(elt => elt.name))
      }
      if (this.seanceTypes) {
        texts = texts.concat(this.seanceTypes.map(elt => elt.name))
      }
      if (this.periods) {
        texts = texts.concat(this.periods.map(elt => elt.name))
      }
      return texts.join(' - ')
    },
    async loadStats() {
      this.analyticAccounts = []
      this.items = []
      if (this.isValid) {
        let url = '/stats/api/youth-welfare/'
        let data = {
          'youth_homes': this.youthHomes.map(elt => elt.id),
          'seance_types': this.seanceTypes.map(elt => elt.id),
          'periods': this.periods.map(elt => elt.id),
          'start_date': this.startDate ? dateToString(this.startDate, 'YYYY-MM-DD') : null,
          'end_date': this.endDate ? dateToString(this.endDate, 'YYYY-MM-DD') : null,
        }
        if (this.familyLevel1 || this.familyLevel2) {
          data.family_levels = [this.familyLevel1, this.familyLevel2]
        }
        if (this.age1 || this.age2) {
          data.ages = [this.age1, this.age2]
        }
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          const analyticAccounts = resp.data['analytic_accounts'].map(elt => makeAnalyticAccount(elt))
          const analyticAccountsMap = new Map()
          for (const analyticAccount of analyticAccounts) {
            analyticAccountsMap.set(analyticAccount.id, analyticAccount)
          }
          this.analyticAccounts = analyticAccounts
          this.analyticAccountsMap = analyticAccountsMap
          this.welfare = resp.data['welfare'].map(elt => makeSeanceWelfare(elt))
          const items = []
          for (const jsonItem of resp.data.items) {
            const item = {
              individual: makeIndividual(jsonItem.individual),
              entity: makeEntity(jsonItem.entity),
              familyLevel: jsonItem['family_level'],
              counters: jsonItem.counters,
              amounts: jsonItem.amounts,
              welfare: jsonItem.welfare,
              key: jsonItem.key,
              analyticAccountId: jsonItem['analytic_account'],
            }
            items.push(item)
          }
          this.items = items
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      } else {
        this.schoolDayAnalytics = []
        this.holidayAnalytics = []
      }
    },
    onListFilterChanged(evt) {
      if (evt.id === this.id) {
        this.youthHomes = evt.youthHomes
        this.seanceTypes = evt.seanceTypes
        this.periods = evt.periods
        this.loadData()
      }
    },
    onChange() {
      this.loadData()
    },
    onListFilterLoaded(evt) {
    },
    onDateFrameChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadData()
    },
    sumAmount(item) {
      return sum(Object.values(item.amounts))
    },
    sumWelfare(item) {
      return sum(Object.values(item.welfare))
    },
    sumBefore(item) {
      return this.sumAmount(item) + this.sumWelfare(item)
    },
    totalAmount() {
      return sum(this.items.map(item => this.sumAmount(item)))
    },
    totalWelfare() {
      return sum(this.items.map(item => this.sumWelfare(item)))
    },
    totalBefore(item) {
      return this.totalAmount(item) + this.totalWelfare(item)
    },
    sumAnalytic(analytic) {
      let value = 0
      for (const item of this.items.filter(elt => elt.analyticAccountId === analytic.id)) {
        value += this.sumAmount(item)
      }
      return value
    },
    getAnalyticCode(item) {
      if (this.analyticAccountsMap.has(item.analyticAccountId)) {
        return this.analyticAccountsMap.get(item.analyticAccountId).name
      }
      return ''
    },
    getCategoryValue(col, item, raw = false) {
      const value = item[col.field][col.category]
      if (value) {
        if (!raw && (col.field === 'welfare')) {
          return currency(value)
        } else {
          return value
        }
      }
      return raw ? 0 : ''
    },
    getCategorySum(col) {
      const value = sum(this.items.map(item => this.getCategoryValue(col, item, true)))
      if (value) {
        if (col.field === 'welfare') {
          return currency(value)
        } else {
          return value
        }
      }
      return ''
    },
  },
}
</script>

<style scoped lang="less">
.youth-welfare-table {
  .section {
    margin: 10px 0;
  }
  .section-header {
    background: #ccc;
    padding: 5px;
  }

  table.full-table {
    width: 100%;
  }

  table.full-table td, table.full-table th {
    padding: 3px 3px;
    font-size: 12px;
  }

  table.full-table th {
    background: #ddd;
  }

  table.full-table tr:nth-child(odd) th {
    background: #ccc;
  }

  table.full-table td.value-col, table.full-table th.value-col {
    max-width: 40px;
    overflow: hidden;
  }

  table.full-table th.header-col {
    font-size: 9px;
  }

  .sub-title {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .number {
    text-align: right;
  }

  .filter-bar {
    background: #e0e0e0;
    padding: 10px 5px;
    border-top: solid 1px #888;
  }

  .filter-bar-optional {
    border-top: solid 1px #888;
    background: #e0e0e0;
    padding: 10px 5px;
  }

  .small-filter-title {
    font-size: 12px;
    margin-bottom: 5px;
  }
  th {
    text-align: center;
  }
  th.number {
    text-align: right;
  }
  th.col {
    width: 100px;
  }
  td.welfare-col {
    background: #f8efc0;
  }
  tr:nth-child(odd) td.welfare-col {
    background: #ede5b8 !important;
  }
  th.welfare-col {
    background: #ffea1a !important;
  }
  td.total-col {
    background: #c3f5f9;
  }
  tr:nth-child(odd) td.total-col {
    background: #b9eaee !important;
  }
  th.total-col {
    background: #8edfe6 !important;
  }
}
</style>
